export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"msapplication-TileColor","content":"#ffc40d"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/assets/favicons/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/assets/favicons/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/assets/favicons/favicon-16x16.png"},{"rel":"manifest","href":"/assets/favicons/site.webmanifest"},{"rel":"mask-icon","href":"/assets/favicons/safari-pinned-tab.svg","color":"#f5c95b"}],"style":[],"script":[{"src":"/googtrans/callback.js"},{"src":"/googtrans/entry.js?cb=TranslateInit"}],"noscript":[],"title":"Think Big Act Now"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false