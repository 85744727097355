import revive_payload_client_mnSeRtUg1M from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.11.19_@unocss+reset@0.60.3_encoding@0.1.13_e_pt6mcsv5xdz3ixglsdisjmax5e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_8PS9VUo2b5 from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.11.19_@unocss+reset@0.60.3_encoding@0.1.13_e_pt6mcsv5xdz3ixglsdisjmax5e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GsRskvikWt from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.11.19_@unocss+reset@0.60.3_encoding@0.1.13_e_pt6mcsv5xdz3ixglsdisjmax5e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_DG117AEySM from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.11.19_@unocss+reset@0.60.3_encoding@0.1.13_e_pt6mcsv5xdz3ixglsdisjmax5e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_kW2AUaU4pc from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.11.19_@unocss+reset@0.60.3_encoding@0.1.13_e_pt6mcsv5xdz3ixglsdisjmax5e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_aOvEX0BhLy from "/workspace/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@3.4.27_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_fqYVF2d6XH from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.11.19_@unocss+reset@0.60.3_encoding@0.1.13_e_pt6mcsv5xdz3ixglsdisjmax5e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_6oHH7Dhdj0 from "/workspace/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._gzoawatek5sulysug4fhxzyv7y/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/workspace/.nuxt/sentry-client-config.mjs";
import plugin_client_ufPBGDDvNB from "/workspace/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.4_rollup@4.18.0_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/workspace/.nuxt/unocss.mjs";
import chunk_reload_client_9WgpGUUVpk from "/workspace/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.11.19_@unocss+reset@0.60.3_encoding@0.1.13_e_pt6mcsv5xdz3ixglsdisjmax5e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_mnSeRtUg1M,
  unhead_8PS9VUo2b5,
  router_GsRskvikWt,
  payload_client_DG117AEySM,
  check_outdated_build_client_kW2AUaU4pc,
  plugin_vue3_aOvEX0BhLy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_fqYVF2d6XH,
  sentry_client_6oHH7Dhdj0,
  sentry_client_config_o34nk2sJbg,
  plugin_client_ufPBGDDvNB,
  unocss_MzCDxu9LMj,
  chunk_reload_client_9WgpGUUVpk
]